// 定期报告
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'ZLMC',
                label: '资料名称',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'LX',
                label: '类型',
                // transList: {
                //     1: '保护状况报告',
                //     2: '反应性监测报告',
                //     3: '参考文档'
                // }
            }, {
                prop: 'TJSJ',
                label: '提交时间',
                minWidth: 105,
                sortable: 'custom'
            }, {
                prop: 'LJ',
                label: '报告文件',
                showFileBtn: true,
            }],
        },
    },
    snSearch: {
        selectData: [{
            label: '报告类型',
            clearable: true,
            placeholder: "请选择报告类型",
            list: [{
                ID: '1',
                NAME: '保护状况报告'
            }, {
                ID: '2',
                NAME: '反应性监测报告'
            }, {
                ID: '3',
                NAME: '参考文档'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'LX',
            operateType: 'search',
            isShow: true
        }]
    }
};
export default selfData;